import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateTotalRunningTime} from '../../actions';

function TotalRunningTime({totalRunningTime, updateTotalRunningTime, user}) {
    const handleUpdateTotalRunningTime = useCallback(() => {
        let userTotalRunningTime = Number(prompt('Total Running Time (hours)', totalRunningTime));

        if (userTotalRunningTime && userTotalRunningTime !== totalRunningTime) {
            if (userTotalRunningTime < totalRunningTime) {
                let isConfirmedTime = window.confirm("Are you sure? It can be wrong!");

                if (isConfirmedTime) {
                    updateTotalRunningTime(userTotalRunningTime, user.uid);
                }
            } else {
                updateTotalRunningTime(userTotalRunningTime, user.uid);
            }
        }
    }, [totalRunningTime, updateTotalRunningTime, user.uid]);

    return (
        <div className="gui-card main-box__card">
            <span className="small-title">Total running time:</span>
            <div className="main-box__value-container">
                <div className="large-title">{totalRunningTime} <span className={'large-title__sub'}>h</span></div>
                <button
                    type={'button'}
                    className={'gui-round-btn main-list-item__btn'}
                    onClick={handleUpdateTotalRunningTime}>
                    Put
                </button>
            </div>
        </div>
    )
}

TotalRunningTime.propTypes = {
    totalRunningTime: PropTypes.number.isRequired,
    updateTotalRunningTime: PropTypes.func,
    user: PropTypes.object,
};

const mapStateToProps = (state) => {
    const {serviceData: {data}, user} = state;
    const {totalRunningTime} = data;

    return {totalRunningTime, user};
};

const mapDispatchToProps = {
    updateTotalRunningTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalRunningTime);
