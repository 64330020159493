import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCo8SNx62mOIMI6p_sh_pKrkltzdU5wp78",
    authDomain: "running-time-app.firebaseapp.com",
    projectId: "running-time-app",
    storageBucket: "running-time-app.appspot.com",
    messagingSenderId: "904395985677",
    appId: "1:904395985677:web:f1b3b619e5b8efb8ad5644",
    measurementId: "G-BWG13YRWYF"
};

// Initialize Firebase
export const initFirebase = () => firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth;

export const signUp = (email, password) => auth().createUserWithEmailAndPassword(email, password);

export const signIn = (email, pass) => auth().signInWithEmailAndPassword(email, pass);

export const logOut = () => auth().signOut();

export const onAuthStateChanged = (func) => auth().onAuthStateChanged((user) => func(user));

export const db = firebase.firestore;

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
