export const UPDATE_TOTAL_RUNNING_TIME = 'UPDATE_TOTAL_RUNNING_TIME';
export const UPDATE_TRIP_TIME = 'UPDATE_TRIP_TIME';
export const EDIT_PERIOD = 'EDIT_PERIOD';

export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';

export const FETCH_SERVICE_DATA_START = 'FETCH_SERVICE_DATA_START';
export const FETCH_SERVICE_DATA_SUCCESS = 'FETCH_SERVICE_DATA_SUCCESS';
export const FETCH_SERVICE_DATA_FAILURE = 'FETCH_SERVICE_DATA_FAILURE';
