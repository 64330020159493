import React, {useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as R from 'ramda';
import Icon from '../components/Icon';
import TotalRunningTime from '../smartComponents/TotalRunningTime';
import SmartMainMenuItem from '../smartComponents/SmartMainMenuItem';
import {getServiceData} from '../actions';
import {logOut} from '../firebase';

function MainScreen({fetching, fetched, error, menu, user, getServiceData}) {
    const memoizedTotalRunningTime = useMemo(() => <TotalRunningTime />, []);

    useEffect(() => {
        if (user.loaded && user.uid) {
            getServiceData(user.uid);
        }
    }, [getServiceData, user.loaded, user.uid]);

    return (
        <>
            <div className="main-box__header">
                <h1 className={'main-title text-highlight'}>Running Time</h1>
                <button type={'button'} className={'gui-icon-btn main-box__logout'} onClick={logOut}>
                    <Icon name={'logout'} size={'md'} classNames={'text-highlight'} />
                </button>
                {memoizedTotalRunningTime}
            </div>
            <div className="main-box__content">
                {fetching ? (
                    <h2>Loading…</h2>
                ) : null}
                {fetched && !error ? (
                    <ul className={'main-list'}>
                        {R.values(menu).map((item) =>
                            <SmartMainMenuItem
                                key={item.id}
                                id={item.id}
                                title={item.title}
                                trip={item.trip}
                                period={item.period}/>)
                        }
                    </ul>
                ) : null}
            </div>
        </>
    );
}

MainScreen.defaultProps = {
    menu: {},
};

MainScreen.propTypes = {
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    error: PropTypes.bool,
    menu: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    getServiceData: PropTypes.func,
};

const mapStateToProps = (state) => {
    const {serviceData: {data, fetching, fetched, error}, user} = state;
    const {menu} = data;

    return {fetching, fetched, error, menu, user};
};

const mapDispatchToProps = {
    getServiceData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
