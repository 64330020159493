import React, {useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MainMenuItem from '../../components/MainMenuItem';
import {updateTripTime, editPeriod} from '../../actions';

function SmartMainMenuItem(props) {
    const {
        id,
        title,
        trip,
        period,
        totalRunningTime,
        user,
        updateTripTime,
        editPeriod,
    } = props;
    const tripTime = useMemo(() => totalRunningTime - trip, [totalRunningTime, trip]);
    const isUp = useMemo(() => tripTime >= period, [period, tripTime]);

    const handleUpdateTripTime = useCallback(() => {
        let userTripTime = Number(prompt('Start new trip from (hours)', totalRunningTime));

        if (userTripTime) {
            updateTripTime({id, tripTime: userTripTime}, user.uid);
        }
    }, [id, totalRunningTime, updateTripTime, user.uid]);

    const handleEditPeriod = useCallback(() => {
        let userPeriod = Number(prompt('Enter your period (hours)', period));

        if (userPeriod) {
            editPeriod({id, period: userPeriod}, user.uid);
        }
    }, [period, editPeriod, id, user.uid]);

    return (
        <MainMenuItem
            isUp={isUp}
            title={title}
            trip={tripTime}
            period={period}
            onClickButton={handleUpdateTripTime}
            onClickEdit={handleEditPeriod}/>
    )
}

SmartMainMenuItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string.isRequired,
    trip: PropTypes.number.isRequired,
    period: PropTypes.number.isRequired,
    totalRunningTime: PropTypes.number.isRequired,
    user: PropTypes.object,
    updateTripTime: PropTypes.func,
    editPeriod: PropTypes.func,
};

const mapStateToProps = (state) => {
    const {serviceData: {data}, user} = state;
    const {totalRunningTime} = data;

    return {totalRunningTime, user};
};

const mapDispatchToProps = {
    updateTripTime,
    editPeriod,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartMainMenuItem);
