import * as R from 'ramda';
import {
    UPDATE_TOTAL_RUNNING_TIME,
    UPDATE_TRIP_TIME,
    EDIT_PERIOD,
    FETCH_SERVICE_DATA_START,
    FETCH_SERVICE_DATA_SUCCESS,
    FETCH_SERVICE_DATA_FAILURE,
} from '../actionTypes';

const initialState = {
    fetching: false,
    fetched: false,
    error: false,
    data: {
        totalRunningTime: 0,
        menu: {},
    },
};

const updateMenuItemById = (id, itemData, menu) => R.map((n) => R.ifElse(
    R.propEq('id', id),
    R.mergeLeft(itemData),
    () => n,
)(n), menu);

let serviceData = (state = initialState, {type, payload}) => {
    switch (type) {
        case FETCH_SERVICE_DATA_START:
            return R.mergeRight(state, {
                fetching: true,
            });
        case FETCH_SERVICE_DATA_SUCCESS:
            return R.mergeRight(state, {
                fetched: true,
                fetching: false,
                error: false,
                data: R.mergeRight(state.data, payload),
            });
        case FETCH_SERVICE_DATA_FAILURE:
            return R.mergeRight(state, {
                fetched: true,
                fetching: false,
                error: true,
            });
        case UPDATE_TOTAL_RUNNING_TIME:
            const updatedDataTotalRunningTime = R.mergeRight(state.data, {totalRunningTime: payload});

            return R.mergeRight(state, {
                data: updatedDataTotalRunningTime,
            });
        case UPDATE_TRIP_TIME:
            let updatedTripTime = updateMenuItemById(
                payload.id,
                {trip: payload.tripTime},
                state.data.menu,
            );
            let updatedTripTimeMenu = R.mergeRight(state.data, {menu: updatedTripTime});

            return R.mergeRight(state, {
                data: updatedTripTimeMenu,
            });
        case EDIT_PERIOD:
            let updatedPeriod = updateMenuItemById(
                payload.id,
                {period: payload.period},
                state.data.menu,
            );
            let updatedPeriodMenu = R.mergeRight(state.data, {menu: updatedPeriod});

            return R.mergeRight(state, {
                data: updatedPeriodMenu,
            });
        default:
            return state
    }
}

export default serviceData;
