import React from 'react';
import PropTypes from 'prop-types';

function Icon({name, size, classNames}) {
    return (
        <span className={`material-icons-outlined gui-icon gui-icon--${size} ${classNames}`}>{name}</span>
    )
}

Icon.defaultProps = {
    size: 'xs',
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

export default Icon;
