import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

function MainMenuItem({as, isUp, title, trip, period, onClickButton, onClickEdit}) {
    const CustomTag = `${as}`;

    return (
        <CustomTag className={`gui-card main-list-item ${isUp ? 'main-list-item--danger' : null}`}>
            <span className={'main-list-item__left'}>
                <span className={'main-list-item__icon'}>
                    <Icon name={'settings'} size={'md'} classNames={'text-info'} />
                </span>
                <span>{title}</span>
            </span>
            <span className={'main-list-item__right'}>
                <span className={'text-secondary main-list-item__value'}>
                    <b className={isUp ? 'text-danger' : 'text-secondary'}>{trip}h</b> / <span className={'text-info'}>{period}h</span>
                    <button
                        type={'button'}
                        className={'gui-round-btn gui-round-btn--secondary gui-round-btn--sm u-ml-5 u-mt-3-neg'}
                        onClick={onClickEdit}>
                            <Icon name={'edit'} />
                    </button>
                </span>
                <button
                    type={'button'}
                    className={'gui-round-btn main-list-item__btn'}
                    onClick={onClickButton}>New trip</button>
            </span>
        </CustomTag>
    )
}

MainMenuItem.defaultProps = {
    as: 'li',
};

MainMenuItem.propTypes = {
    as: PropTypes.oneOf(['li', 'div']),
    isUp: PropTypes.bool,
    title: PropTypes.string.isRequired,
    trip: PropTypes.number.isRequired,
    period: PropTypes.number.isRequired,
    onClickButton: PropTypes.func,
    onClickEdit: PropTypes.func,
};

export default MainMenuItem;
