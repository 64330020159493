import {
    UPDATE_TOTAL_RUNNING_TIME,
    UPDATE_TRIP_TIME,
    EDIT_PERIOD,
    SET_USER,
    RESET_USER,
    FETCH_SERVICE_DATA_START,
    FETCH_SERVICE_DATA_SUCCESS,
    FETCH_SERVICE_DATA_FAILURE,
} from '../actionTypes';
import {db} from '../firebase';

export const getServiceData = (uid) => {
    return async dispatch => {

        dispatch({
            type: FETCH_SERVICE_DATA_START,
        });

        try {
            // const docRef = db().collection("serviceData").doc(uid);
            // const doc = await docRef.get();
            //
            // if (doc.exists) {
            //     dispatch({
            //         type: FETCH_SERVICE_DATA_SUCCESS,
            //         payload: doc.data(),
            //     });
            // } else {
            //     dispatch({
            //         type: FETCH_SERVICE_DATA_FAILURE,
            //     });
            // }

            db().collection("serviceData").doc(uid)
                .onSnapshot((doc) => {
                    if (doc.exists) {
                        dispatch({
                            type: FETCH_SERVICE_DATA_SUCCESS,
                            payload: doc.data(),
                        });
                    } else {
                        dispatch({
                            type: FETCH_SERVICE_DATA_FAILURE,
                        });
                    }
                });
        } catch (error) {
            // console.log("Error getting document:", error);
            dispatch({
                type: FETCH_SERVICE_DATA_FAILURE,
            });
        }
    }
};

export const updateTotalRunningTime = (payload, uid) => {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_TOTAL_RUNNING_TIME,
                payload,
            });

            db().collection("serviceData").doc(uid).update({
                "totalRunningTime": payload,
            })
            .then(() => {
                console.log("Document successfully updated!");
            })
            .catch((error) => console.log('error', error));
        } catch (error) {
            console.log('error', error)
        }
    }
};

export const updateTripTime = (payload, uid) => {
    return async dispatch => {
        try {
            dispatch({
                type: UPDATE_TRIP_TIME,
                payload,
            });

            db().collection("serviceData").doc(uid).update({
                [`menu.${payload.id}.trip`]: payload.tripTime,
            })
            .then(() => {
                console.log("Document successfully updated!");
            });
        } catch (error) {
            // dispatch({
            //     type: FETCH_OTHER_DATA_FAILURE,
            //     payload: error,
            // })
        }
    }
};

export const editPeriod = (payload, uid) => {
    return async dispatch => {
        try {
            dispatch({
                type: EDIT_PERIOD,
                payload,
            });

            db().collection("serviceData").doc(uid).update({
                [`menu.${payload.id}.period`]: payload.period,
            })
            .then(() => {
                console.log("Document successfully updated!");
            });
        } catch (error) {
            // dispatch({
            //     type: FETCH_OTHER_DATA_FAILURE,
            //     payload: error,
            // })
        }

    }
};

export const setUser = (payload) => {
    return {
        type: SET_USER,
        payload,
    }
};

export const resetUser = () => {
    return {
        type: RESET_USER,
    }
};
